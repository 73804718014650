import React from "react"
import styles from "./article-pages.module.css"
import {Link} from "gatsby";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import LinkButton from "../buttons/link-button/link-button";
import Background from "../../../static/articles/vannaya/bg.jpg";

const Vannaya = () => {

    return (
        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
                <div className={modal ? styles.articleModal : styles.articlePage}>
                    {modal ? (
                        <Link className={"close-modal"} to={"/#articles"}>&times;</Link>
                    ) : "" }
                    <div className={styles.articleBackground} style={{
                        backgroundImage: `url(${Background})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}>
                        <div className={styles.imageBackground}>
                            <p className={styles.backgroundText}>
                                Дизайны ванных комнат 2020
                            </p>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <h3>Дизайн ванной комнаты в 2019-м и
                            2020-м будет сосредоточен на цвете.</h3>

                        <p>С эволюцией в лайфстайл-пространство ванная комната переживает возвращение цвета.
                            Цветовые «преступления» 60-х и 70-х годов забыты: теперь главное — избежать скуки
                            любой ценой. Эксперты ISH выделяют 12 цветовых трендов, которые способствуют
                            превращению ванной из утилитарного помещения в пространство для жизни и будут особенно
                            влиять на ее дизайн. Их продемонстрирует инсталляция Colour Selection.</p>

                        <p><strong>1. Cерый — тоже цвет</strong></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/1.jpg"/>
                        </div>

                        <p>Распространенный сегодня серый уже был популярен в 1980-х, а теперь снова вернулся. В
                            винтажной стилистике серый популярен как фоновый оттенок, поскольку не разрушает эффект
                            старины, а привычный белый выглядит сияющим в комбинации с ним. Негативные ассоциации с
                            унынием и плохой погодой в прошлом: серый — это модный цвет с длительным сроком
                            годности, создающий привлекательный вневременной образ. Помимо всех оттенков
                            светло-серого, также в тренде цвета с синим, зеленым или коричневым подтоном. С помощью
                            серого можно создать любую атмосферу — от ультрасовременной до уютной. И белая, и
                            цветная сантехника отлично смотрится с серым, как и фурнитура со стандартной хромовой
                            отделкой.</p>

                        <p><strong>2. Коричневый возвращается</strong></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/2.jpg"/>
                        </div>

                        <p>Ванная комната все больше превращается в уютное жилое пространство. Секрет актуального
                            коричневого — в выборе деликатных полутонов, ведь коричневая палитра может включать
                            оттенки грязи и глины, терракоты и оливы, бронзы и ржавчины, соснового леса и кедрового
                            дерева, пыли и камня — возможности безграничны. Современная интерпретация
                            коричневого предполагает два варианта. Либо в виде цветовой гаммы, вдохновленной
                            природой, — ее часто используют в сочетании с натуральными материалами. Либо в
                            качестве художественной отсылки на исторические стили.</p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/3.jpg"/>
                        </div>

                        <p>Главный фаворит — дуб, предпочтительно с отделкой, неважно, используется ли он для
                            стен, столешниц или пола. Появляются элегантные композиции с более темными оттенками
                            древесины от коньячного до мореного дуба. В этом случае коричневая цветовая гамма
                            используется в подчеркнуто «премиальной» стилистике, чтобы добавить роскоши ар-деко
                            модернистской ванной.</p>

                        <p><strong>3. Золотые блики</strong></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/4.jpg"/>
                        </div>

                        <p>Золото — один из самых драгоценных металлов, который позволяет создать ощущение
                            дорогого интерьера. Другой похожий на него цвет — медный — также очень
                            популярен и широко используется в оформлении традиционных ванных комнат. В целом
                            существует большой спрос на металлические поверхности, которые теперь доступны в
                            различных отделках благодаря новым технологиям.</p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/5.jpg"/>
                        </div>

                        <p>Но несмотря на популярность платины и меди, золото находится в другой лиге: когда дело
                            касается дизайна ванной комнаты, оно выходит на первый план. Как и в случае с настоящим
                            золотом, сияющие поверхности производят более сильное впечатление, когда не используются
                            слишком интенсивно. Золото не должно быть единственным цветом в палитре. Оно чаще
                            используется в сочетании с черным или темными оттенками зеленого и синего.</p>

                        <p><strong>4. Космический черный</strong></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/6.jpg"/>
                        </div>

                        <p>Модный вариант «белым по белому» (не путать с обычными ванными комнатами, оформленными в
                            монотонном белом просто из-за отсутствия лучших идей) сталкивается с серьезной
                            конкуренцией в виде варианта «черным по черному». Эта тенденция пришла из сферы жилых
                            интерьеров и находит все больше поклонников, поскольку черный выглядит одновременно
                            роскошно, элегантно и современно.</p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/7.jpg"/>
                        </div>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/8.jpg"/>
                        </div>

                        <p>Скомбинированный с мрамором (особенно с модным зеленым) и кожей, черный превращается в
                            стильную классику. Благодаря совершенствованию производственных процессов даже
                            производители смесителей теперь предлагают выбор темных оттенков, вплоть до глубокого
                            черного. От черных душевых кабин и черной мебели до консолей под раковину из черной
                            эмалированной стали — почти каждый продукт для ванной теперь доступен в этом
                            варианте.</p>

                        <p><strong>5. Всплеск яркого цвета</strong></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/9.jpg"/>
                        </div>

                        <p>Теперь яркие цвета сознательно используются для ванных комнат взрослых — смелые
                            краски и контрасты применяются для создания оптимистичной атмосферы. И хотя яркая ванная
                            комната может выглядеть игриво, она определенно не наивна. Вместо этого она представляет
                            собой акт неповиновения, своего рода отношение «когда-если-не-сейчас», возникающее в
                            творческих кругах и выражающееся в виде непривычных и свободных сочетаний цветов и
                            узоров — особенно в мире моды.</p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/10.jpg"/>
                        </div>

                        <p>Помимо интенсивных оттенков красного, зеленого и синего, в этот круг также входят такие
                            варианты, как теплый оранжевый, таинственный фиолетовый и элегантный янтарно-желтый. На
                            ISH посетители смогут увидеть ванные комнаты в провокационных цветах — от
                            умывальников в ярких оттенках до прозрачной мебели для ванной комнаты в синих и зеленых
                            тонах.</p>

                        <p><strong>6. Цветовая гармония</strong></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/11.jpg"/>
                        </div>

                        <p>Это вариант для тех, кто хочет получить эффектное сочетание цветов без чрезмерности ярких
                            красок. Цветовые аккорды, то есть сочетания нескольких цветов одинаковой интенсивности и
                            качества, являются, вероятно, наиболее сложным вариантом, но также и самым
                            индивидуальным. Это может быть, например, горчично-желтый диван с синими и
                            желтовато-серыми подушками на фоне синей стены.</p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/12.jpg"/>
                        </div>

                        <p>Грамотно подобранные сочетания создают изящный, художественный, многоцветный образ.
                            Цветовые гармонии можно создать из соседних оттенков (например, синего и зеленого),
                            используя только «холодные» цвета (например, синие и фиолетовые), только «теплые»
                            оттенки (оранжевый, желтый, красный) или сочетая хроматические и ахроматические цвета
                            (например, различные оттенки синего в комбинации с несколькими оттенками серого и
                            серого).</p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/13.jpg"/>
                        </div>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/14.jpg"/>
                        </div>

                        <p>Выбрать подходящие цвета помогут различные программы и приложения (например, Capture CC
                            от Adobe или Colourpin II от NCS).</p>

                        <p><strong>7. Схемы «тон в тон»</strong></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/15.jpg"/>
                        </div>

                        <p>В случаях, когда в интерьере доминирует один цвет, он редко используется исключительно
                            соло. Вместо этого поверхности градуируются разными оттенками и полутонами одного цвета.
                            Даже без добавления контрастов это приводит к едва различимому ощущению глубины, которое
                            чрезвычайно выразительно благодаря монохроматической палитре.</p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/16.jpg"/>
                        </div>

                        <p>Для градации также можно использовать смену материалов — например, определенный
                            оттенок камня или дерева, который особенно хорошо гармонирует с основным цветом или
                            другой текстурой поверхности, чтобы создать более глубокое затенение.</p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/17.jpg"/>
                        </div>

                        <p>«Тон в тон» — это проверенная и актуальная концепция в моде, где для создания
                            элегантных ансамблей достаточно просто использовать несколько различных материалов,
                            например, сочетая шерсть, лакированную и фактурную кожу в одном и том же оттенке. С
                            учетом сенсорной перегрузки, с которой мы сталкиваемся в нашей повседневной жизни, этот
                            эффект — настоящий отдых для души.</p>

                        <p><strong>8. Зеленый оазис</strong></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/18.jpg"/>
                        </div>

                        <p>У этой тенденции две причины. Во-первых, тоска по природной среде, когда природа
                            воспринимается как первобытный дом человечества. А во-вторых, зеленый — это
                            трендовый цвет, который создает прекрасное настроение, особенно в сочетании с
                            растениями. Более того, сочетание разных оттенков зеленого достаточно беспроблемно.</p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/19.jpg"/>
                        </div>

                        <p>Выразительные объекты, такие как отдельно стоящая ванна, могут использоваться как
                            заявление, а цветовое пространство — варьироваться от таинственного темно-зеленого
                            цвета для создания роскошной, комфортной ванной комнаты, вплоть до оттенка свежей
                            майской зелени — отличный выбор для молодых семей. Кроме того, зеленый —
                            прекрасная основа для создания интригующих «историй», например, в сочетании с крупными
                            узорами, цветочными обоями, фотообоями и небольшими джунглями из живых растений, которые
                            добавят экзотики в любую ванную комнату.</p>

                        <p><strong>9. Пастельные оттенки</strong></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/20.jpg"/>
                        </div>

                        <p>Ванные комнаты в пастельных тонах как по волшебству создают новый мир — иногда
                            модный, иногда причудливый, но всегда с примесью эскапизма. В ванной комнате эти цвета в
                            основном используются для внешних поверхностей ванн или раковин.</p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/21.jpg"/>
                        </div>

                        <p>Светлые пастельные оттенки особенно подходят для небольших ванных комнат, поскольку
                            делают пространство визуально больше, особенно в сочетании с широкоформатной плиткой и
                            ровными стенами. Эффект особенно хорошо работает с серым, поэтому неудивительно, что
                            сочетание розового и серого — одна из самых популярных цветовых комбинаций для
                            ванной комнаты прямо сейчас. Сочетание розового с коричневыми элементами —
                            например, полом из светлого дерева или плиткой — подчеркивает уютный вид. А
                            пудровый сумеречно-розовый в сочетании с белым и серым — это настоящая
                            романтика.</p>

                        <p><strong>10. Серовато-бежевый</strong></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/22.jpg"/>
                        </div>

                        <p>В наши дни невозможно представить цветовую палитру современной архитектуры и дизайна
                            интерьера без оттенка greige. Он ассоциируется с природой и загородными домами, но также
                            немного напоминает пол в нью-йоркском лофте. Он сочетается и с винтажным стилем, и с
                            минималистичными решениями. Неологизм greige означает сочетание серого и бежевого, и это
                            лежит в основе цветового принципа в этой тенденции: сложный составной цвет как
                            современный нейтральный основной оттенок с чрезвычайно гармонизирующим эффектом. Так же,
                            как greige получается путем смешивания серого со светло-коричневым, возможно смешать
                            серый с синим (что создаст более прохладный образ).</p>

                        <p><strong>11. Все оттенки синевы</strong></p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/23.jpg"/>
                        </div>

                        <p>Синий, пожалуй, самый популярный цвет в европейских странах. Мы связываем синий с
                            расслаблением, простором неба и живительной водой. В последние годы производители
                            значительно модернизировали палитру синих оттенков, создавая не только инновационные
                            цвета для отделки стен, но и новые оттенки для плитки, которая также создают эффект уюта
                            в ванной. Переосмысление мрамора в мире дизайна интерьера поощряет использование синей
                            сантехники.</p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/24.jpg"/>
                        </div>

                        <p>В сочетании с песочными оттенками синий создает образ, который соотносится с популярной
                            скандинавской стилистикой. И любой, кто ищет альтернативу модному черному тренду, может
                            вместо этого выбрать роскошный ультрамарин. Чем ярче оттенок, тем роскошнее он
                            выглядит.</p>

                        <p><strong>12. Белый — сам по себе или с множеством контрастов</strong> </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/25.jpg"/>
                        </div>

                        <p>Белый означает чистоту. Вот почему он доминирует в ванных комнатах на протяжении
                            десятилетий и служит основой для широкого спектра дизайнерских решений. 45% немцев
                            выбирают белый основным цветом, что дает ему огромное преимущество перед бежевым (11%),
                            серым (10%) и синим (8%). Тем временем производители сантехники добавили различные
                            оттенки белого в свой ассортимент, хотя этим новым вариантам все еще трудно
                            прижиться.</p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/vannaya/26.jpg"/>
                        </div>

                        <p>Лучший способ подчеркнуть дизайнерские качества белой сантехники — сочетать ее с
                            яркими аксессуарами и гостеприимным видом помещения в целом. Выбрать для контраста
                            красочную мебель или напольную плитку — еще один принцип, гарантирующий избавление
                            от скуки. Например, классический черно-белый дизайн — менее эксцентричная
                            альтернатива ультрамодной черной ванной комнате.</p>

                        <div style={{textAlign: 'center', marginTop: '40px'}}>
                            <LinkButton text={'Вернуться на сайт'} path={modal ? "/#articles" : ""}/>
                        </div>
                    </div>
                </div>
            )}
        </ModalRoutingContext.Consumer>
    );
};

export default Vannaya
