import React from "react"
import Container from "../components/container/container";
import SEO from "../components/seo";
import YM from "../components/analytics/ym";
import Vannaya from "../components/articles/dizain-vannaya-komnata";

const VannayaPage = () => (
    <Container>
        <SEO
            title="complexstroy.by | Дизайны ванных комнат 2020"
            description="Дизайны ванных комнат 2020"/>
        <YM />
        <Vannaya />
    </Container>
);

export default VannayaPage
